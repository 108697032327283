// import * as Cesium from 'Cesium'

import * as Cesium from "cesium";
import { cesiumToken } from "@/common/constants";
window.CESIUM_BASE_URL = "/cesium/";
Cesium.Ion.defaultAccessToken = cesiumToken;
const FactoryLine = {
    data() {
        return {
            factories: []
        }
    },
    methods: {
        // 查询加工厂
        getFactory() {
            this.$post(this.$api.BASE_MANAGE.LIST, {
                pageNo: 1,
                pageSize: 9999,
                siteType: "3",
                regionCode: "500153",
            }).then((res) => {
                this.factories = res.list;
                for (let i = 0; i < this.factories.length; i++) {
                    let factory = this.factories[i];
                    this.addFactoryDot(factory);
                }
            });
        },
        addFactoryDot(factory) {
            let center
            try {
                center = JSON.parse(JSON.parse(factory.mapPosition)[0].options.center)
            } catch (e) {
                console.log(e)
                center = null
            }
            if (!center) return;
            console.log(factory)
            let point = {
                id: 'fc' + factory.id,
                position: Cesium.Cartesian3.fromDegrees(center.lng, center.lat, 4000),
                billboard: {
                    image: '/imgs/map/factory.png',
                    eyeOffset: new Cesium.Cartesian3(0, 0, -10000),
                    width: 24,
                    height: 24
                },
                label: {
                    text: factory.name,
                    font: "20px bold sans-serif",
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    fillColor: Cesium.Color.fromCssColorString('#fff'),
                    outlineColor: Cesium.Color.fromCssColorString('#000'),
                    outlineWidth: 3,
                    eyeOffset: new Cesium.Cartesian3(0, 0, -10000),
                    pixelOffset: new Cesium.Cartesian2(0, -24)
                }
            }
            let pointEntity = new Cesium.Entity(point)
            this.map.entities.add(pointEntity)
        }
    }
}

export default FactoryLine