import boundary from "@/assets/js/boundary.json";
import * as Cesium from "cesium";
import { cesiumToken } from "@/common/constants";
window.CESIUM_BASE_URL = "/cesium/";
Cesium.Ion.defaultAccessToken = cesiumToken;

const LoadMapMix = {
    data() {
        return {
            // 地图
            map: null,
        }
    },
    methods: {
        initMap() {
            if (!this.map) {
                this.map = new Cesium.Viewer(this.$refs.mapbox, {
                    animation: false,
                    baseLayerPicker: false,
                    fullscreenButton: false,
                    vrButton: false,
                    geocoder: false,
                    homeButton: false,
                    infoBox: false,
                    sceneModePicker: false,
                    selectionIndicator: false,
                    timeline: false,
                    navigationHelpButton: false,
                    skyBox: false,
                    shadows: true,
                    terrainShadows: Cesium.ShadowMode.ENABLED,
                    contextOptions: {
                        webgl: {
                            alpha: true,
                        },
                    },
                });
                this.map.cesiumWidget.creditContainer.style.display = "none";
                this.map.scene.backgroundColor = new Cesium.Color(0.0, 0.0, 0.0, 0.0);
                let entities = [];
                for (let i = 0; i < boundary.features.length; i++) {
                    let feature = boundary.features[i];
                    let name = feature.properties["乡"];
                    let config = feature.geometry.coordinates[0];
                    let positions = null,
                        holes = [];
                    for (let j = 0; j < config.length; j++) {
                        let coordinates = config[j].reduce((total, current, index) => {
                            total.push(current[0])
                            total.push(current[1])
                            return total
                        }, [])
                        let tempPoints = new Cesium.Cartesian3.fromDegreesArray(
                            coordinates
                        );
                        if (!positions) positions = tempPoints;
                        else {
                            holes.push(new Cesium.PolygonHierarchy(tempPoints));
                        }
                    }
                    let hierarchy = new Cesium.PolygonHierarchy(positions, holes);


                    let polylinePoints = config[0].map(
                        (item) => new Cesium.Cartesian3.fromDegrees(+item[0], +item[1], 4000)
                    );
                    let pyCenter = Cesium.BoundingSphere.fromPoints(polylinePoints).center;
                    let fillColor = this.confirmAreaColor(name);
                    let polygonconfig = {
                        id: 'base_area' + i,
                        position: pyCenter,
                        label: {
                            text: name,
                            font: "14px sans-serif",
                            fillColor: Cesium.Color.fromCssColorString('#082c3a'),
                            outlineColor: Cesium.Color.fromCssColorString('#082c3a'),
                            outlineWidth: 1,
                            style: Cesium.LabelStyle.FILL,
                            eyeOffset: new Cesium.Cartesian3(0, 0, -4000),
                        },
                        polygon: {
                            hierarchy: hierarchy,
                            extrudedHeight: 4000,
                            material: Cesium.Color.fromCssColorString(fillColor),
                            outline: false,
                        },
                        polyline: {
                            positions: polylinePoints,
                            width: 2,
                            material: Cesium.Color.fromCssColorString('#a8d2d1'),
                        },
                        properties: {
                            name
                        }
                    };
                    let tempentity = this.map.entities.add(polygonconfig);
                    this.entityIds.add(tempentity.id);
                    entities.push(tempentity);
                }

                this.map.zoomTo(
                    entities,
                    new Cesium.HeadingPitchRange(6.2831851981581455, -0.8, 69000)
                );
                this.getFactory();
                this.addMapEvent();
            }
        },
    }
}

export default LoadMapMix