<template>
  <surround-box specialColor="#093243" background="linear-gradient(270deg, rgba(21, 101, 182, 0) 0%, #3B7AC2 50%, rgba(21, 101, 182, 0) 95%)" title="液体粪污运出量统计">
    <div class="chart-box">
      <div ref="chart" class="chart"></div>
      <div class="unit">单位：m³</div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from '@/components/mapComp/surroundBox4.vue'
import * as echarts from 'echarts'
export default {
  components: {
    surroundBox,
  },
  data() {
    return {
      zhuanyun: [
        // {
        //   year: "2016",
        //   num: 12.1,
        // },
        // {
        //   year: "2017",
        //   num: 13.1,
        // },
        // {
        //   year: "2018",
        //   num: 12.8,
        // },
        // {
        //   year: "2019",
        //   num: 13.6,
        // },
        // {
        //   year: "2020",
        //   num: 13.9,
        // },
      ],
      jiagong: [
        {
          year: '2016',
          num: 5.2,
        },
        {
          year: '2017',
          num: 5.7,
        },
        {
          year: '2018',
          num: 5.6,
        },
        {
          year: '2019',
          num: 6.4,
        },
        {
          year: '2020',
          num: 6.9,
        },
      ],
      shiyong: [
        {
          year: '2016',
          num: 4.4,
        },
        {
          year: '2017',
          num: 4.8,
        },
        {
          year: '2018',
          num: 4.6,
        },
        {
          year: '2019',
          num: 5.1,
        },
        {
          year: '2020',
          num: 5.2,
        },
      ],
      //年份
      year: '',
    }
  },
  methods: {
    //   初始化
    initChart() {
      let chart = echarts.init(this.$refs.chart)
      let options = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          containLabel: true,
          top: 48,
          left: 16,
          right: 32,
          bottom: 16,
        },
        xAxis: {
          data: this.zhuanyun.map((item) => item.year),
          axisLabel: {
            color: '#fff',
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: ['#fff'],
              type: 'dashed',
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(255,255,255,0.5)'],
              type: 'dashed',
            },
          },
        },
        yAxis: {
          axisLabel: {
            color: '#fff',
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(230, 247, 255, 0.20)'],
              type: 'dashed',
            },
          },
        },
        series: [
          {
            type: 'line',
            smooth: true,
            name: '液体运出量',
            data: this.zhuanyun.map((item) => item.num),
            lineStyle: {
              color: '#00F4FF',
            },
            itemStyle: {
              color: '#00F4FF',
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(1, 237, 253, 0.65)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(23, 145, 254, 0.72)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          // {
          //   type: "line",
          //   name: "加工数量",
          //   data: this.jiagong.map((item) => item.num),
          //   lineStyle: {
          //     color: "rgba(151, 14, 255, 1)",
          //   },
          //   areaStyle: {
          //     color: "rgba(151, 14, 255, 0.5)",
          //   },
          // },
          // {
          //   type: "line",
          //   name: "施用数量",
          //   data: this.shiyong.map((item) => item.num),
          //   lineStyle: {
          //     color: "rgba(255, 14, 54, 1)",
          //   },
          //   areaStyle: {
          //     color: "rgba(255, 14, 54, 0.5)",
          //   },
          // },
        ],
      }
      chart.setOption(options)
    },
    //转运统计
    getZhuanyun() {
      // this.$post(this.$api.STATICS_MANAGE.GET_ZHAUNYUN, {
      //   startTime: `${this.year - 5}-01-01 00:00:01`,
      //   endTime: `${this.year}-12-31 23:59:59`,
      // }).then((res) => {
      //   for (let i = 0; i < 6; i++) {
      //     this.zhuanyun.push({
      //       year: 2016 + i,
      //       num: res[2016 + i],
      //     });
      //   }
      //   this.initChart();
      // });
    },
    getZhuanyuns() {
      this.$get(
        `solidmanureutilize/getManureUtilizeOutStatistics?manureType=2`
      ).then((res) => {
        for (let i = 0; i < res.length; i++) {
          this.zhuanyun.push({
            year: res[i].transportOutMonth,
            num: res[i].outNum || 0,
          })
        }
        this.initChart()
      })
      // this.$post(this.$api.STATICS_MANAGE.GET_ZHAUNYUNS, {
      //   resourceTypeId: 115,
      // }).then((res) => {
      //   for (let i = 0; i < res.length; i++) {
      //     this.zhuanyun.push({
      //       year: res[i].dimensionName,
      //       num: res[i].content,
      //     });
      //   }
      //   this.initChart();
      // });
    },
    getDate() {
      let dates = new Date()
      this.year = dates.getFullYear()
      this.getZhuanyun()
    },
  },
  mounted() {
    this.getZhuanyuns()
    this.getDate()
  },
}
</script>
<style lang='less' scoped>
.chart-box {
  width: 100%;
  height: 22.6851vh;
  position: relative;
  .chart {
    width: 100%;
    height: 100%;
    margin-top: 1.5vh;
  }
  .unit {
    position: absolute;
    left: 16px;
    top: 4px;
    color: rgba(255, 255, 255, 0.34);
    font-weight: bold;
  }
}
</style>
