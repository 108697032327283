<template>
  <div class="map-page-container">
    <div class="left-position">
      <!-- <div class="chulan-box"> -->
      <chulan></chulan>
      <!-- </div> -->
      <!-- <div class="guimo-box"> -->
      <guimo></guimo>
      <!-- </div> -->
      <!-- <div class="zhuanyun-box"> -->
      <zhuanyun></zhuanyun>
      <!-- </div> -->
    </div>
    <div class="center-position">
      <div class="map-position-box">
        <echartMap :zoom="1.1" area-id="500153" roam="false"  @area-stat="areaStat"></echartMap>
<!--        <map-component-->
<!--          @farm-change="farmClick"-->
<!--          :currentFarm="currentFarm"-->
<!--          :farmFilter="farmFilter"-->
<!--          @area-click="getArea"-->
<!--          :groupIds="groupIds"-->
<!--          @area-stat="areaStat"-->
<!--          class="map-component"-->
<!--        ></map-component>-->
        <!-- <span
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: none;
            z-index: 999;
            display: flex;
            align-items: center;
            pointer-events: none;
          "
        >
          <img style="width: 100%" src="/imgs/map/lianhe.png" />
        </span>
        <span
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: none;
            z-index: 999;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
          "
        >
          <img style="width: 95%" src="/imgs/map/circle.png" /> -->
<!--          <img class="circle2" src="/imgs/map/circle2.png" />-->
        <!-- </span> -->

        <div class="group-box2">
          <h4>图例</h4>
          <p><span>示范核心区</span><em></em></p>
          <p><span>中部片区</span><em></em></p>
          <p><span>北部片区</span><em></em></p>
          <p><span>西部片区</span><em></em></p>
          <!-- <p><span>粪污集散点</span><img src="../../../public/imgs/map/factory2.png" /></p> -->
        </div>

        <!-- <div class="group-box">
          <group @change-group="changeGroup"></group>
        </div> -->
      </div>
      <div class="center-heng">
        <produce></produce>
        <cunlan-record :areaInfo="areaInfo"></cunlan-record>
      </div>
    </div>
    <div class="right-position">
      <tongji></tongji>
      <chengzai></chengzai>
      <prewarninginfo></prewarninginfo>
    </div>
    <div class="title-box">
      <title-pane></title-pane>
    </div>
  </div>
</template>
<script>
// import MapComponent from "@/components/map/WlwMap";
import MapComponent from "@/components/cesiumMap";
import chulan from "./chulan.vue";
import guimo from "./guimo.vue";
import zhuanyun from "./zhuanyun.vue";
import chengzai from "./chengzai.vue";
import tongji from "./tongji.vue";
import prewarninginfo from "./prewarninginfo.vue";
import legendPan from "./legend2.vue";
import titlePane from "./title2.vue";
import produce from "./produce.vue";
import cunlanRecord from "./cunlanRecord.vue";
// import areaInfo from "./areaInfo.vue";

// import areaInfo from "./areaInfo.vue";
import group from "./group.vue";
import { mapState } from "vuex";
import echartMap from "../../components/map/echartMap/echartMap";
export default {
  components: {
    MapComponent,
    chulan,
    guimo,
    zhuanyun,
    chengzai,
    tongji,
    prewarninginfo,
    legendPan,
    titlePane,
    produce,
    cunlanRecord,
    // areaInfo,
    group,
    echartMap
  },
  data() {
    return {
      farms: [],
      // 基地图例筛选
      farmFilter: new Set(),
      // 设备图例筛选
      siteFilter: new Set(),
      //分组展示
      groupIds: [],
      // 当前展示基地
      currentFarm: null,
      // 当前经纬度
      currentCoordinates: {
        lon: 0,
        lat: 0,
      },
      // 公司列表
      companies: [],
      // 当前查看的公司/基地
      currentCompanyFarm: [],
      // 当前选中的物联网菜单
      currentMenu: "baseInfo",
      currentMenuName: "基础信息",
      currentAreaName: "",
      currentInfoPosition: null,

      areaInfo: null
    };
  },
  computed: {
    ...mapState({
      userCategory: (state) => state.user.user.category,
    }),
  },
  methods: {
    areaStat(res) {
      this.areaInfo = res
    },
    //   查询基地列表
    // getFarms() {
    //   this.$post(this.$api.MAP.MAP_CFG_INFO).then((res) => {
    //     this.farms = res.list.filter((item) => item.mapCenterLocation);
    //     this.getCompany();
    //   });
    // },
    // 基地图例筛选
    farmFilterAction(siteType) {
      let filter = new Set(this.farmFilter.values());
      if (filter.has(siteType)) filter.delete(siteType);
      else filter.add(siteType);
      this.farmFilter = filter;
    },
    //设备图例筛选
    siteFilterAction(siteType) {
      let filter = new Set(this.farmFilter.values());
      if (filter.has(siteType)) filter.delete(siteType);
      else filter.add(siteType);
      this.siteFilter = filter;
    },
    // 设备图例清空
    siteFilterClear() {
      this.siteFilter = new Set();
    },
    // 基地点击
    farmClick(farm) {
      // let coordinates = farm.mapCenterLocation.split(",");
      // this.currentCoordinates.lon = +coordinates[0];
      // this.currentCoordinates.lat = +coordinates[1];
      // let company = this.companies.find(
      //   (item) => item.companyNo == farm.companyNo
      // );
      // this.currentCompanyFarm = [company.value, farm.id];
      this.currentFarm = farm;
    },
    // 切换基地
    changeFarm(value) {
      if (!value) return;
      let farmId = value[1];
      let farm = this.farms.find((item) => item.id == farmId);
      if (!this.currentFarm) this.currentFarm = farm;
      if (farm && farm.id != this.currentFarm.id) this.currentFarm = farm;
    },
    // 切换物联网选项
    currentMenuChange(type) {
      switch (type) {
        case "WeatherPrewarning":
          this.currentMenuName = "气象预报";
          break;
      }
      this.currentMenu = type;
    },
    // 返回概略图
    back() {
      this.currentFarm = null;
      this.currentMenu = "baseInfo";
    },
    getArea(entity) {
      // let currentName = entity?.properties["乡"]?._value || "";
      // this.currentAreaName = currentName;
      // this.currentInfoPosition = entity?.position;
      // this.$refs.areainfo.show()
    },
    // 查询公司
    // getCompany() {
    //   this.$post(this.$api.company.LIST, {
    //     pageNo: 1,
    //     pageSize: 99999,
    //     category: this.userCategory + 1,
    //   }).then((res) => {
    //     let companies = [];
    //     for (let i = 0; i < res.list.length; i++) {
    //       let temp = res.list[i];
    //       let children = this.farms.filter(
    //         (item) => item.companyNo == temp.companyNo
    //       );
    //       if (children.length === 0) continue;
    //       companies.push({
    //         value: temp.id,
    //         label: temp.name,
    //         companyNo: temp.companyNo,
    //         children: children.map((item) => ({
    //           value: item.id,
    //           label: item.sourceName,
    //         })),
    //       });
    //     }
    //     this.companies = companies;
    //   });
    // },
    changeGroup(val) {
      this.groupIds = val;
    },
  },
  mounted() {
    // this.getFarms();
    // this.getCompany();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>
