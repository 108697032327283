const AreaColorType = {
    data() {
        return {
            // 片区分类
            importantArea: new Set([
                "双河街道",
                "清升镇",
                "清江镇",
                "安富街道",
                "广顺街道",
                "昌州街道",
                "峰高街道",
                "直升镇",
            ]),
            centerArea: new Set([
                "昌元街道",
                "荣隆镇",
                "仁义镇",
                "河包镇",
                "古昌镇",
                "万灵镇",
            ]),
            westArea: new Set(["龙集镇", "盘龙镇", "远觉镇", "清流镇"]),
            northArea: new Set(["吴家镇", "铜鼓镇", "观胜镇"]),
        }
    },
    methods: {
        confirmAreaColor(name) {
            if (this.importantArea.has(name)) {
                return '#699bb7'
            }
            if (this.centerArea.has(name)) {
                return '#94ba3b'
            }
            if (this.northArea.has(name)) {
                return '#ad683f'
            }
            if (this.westArea.has(name)) {
                return '#ad982e'
            }
        }
    }
}

export default AreaColorType