<template>
  <div style="width: 100%; height: 100%; overflow: hidden; position: relative">
    <div style="width: 100%; height: 100%" ref="mapbox"></div>
    <infowindow :viewer="map" ref="infowindow"></infowindow>
  </div>
</template>
<script>
import * as Cesium from "cesium";
import "cesium/Build/Cesium/Widgets/widgets.css";
import { cesiumToken, tiandituToken } from "@/common/constants";
import Infowindow from "./Infowindow";
import areaList from "./data.json";
import factoryLineMix from "./farctoryMix";
import areaColorType from "./areaColorType";
import LoadMapMix from "./LoadMapMix";
import boundary from "@/assets/js/boundary.json";

window.CESIUM_BASE_URL = "/cesium/";
Cesium.Ion.defaultAccessToken = cesiumToken;

export default {
  components: {
    Infowindow,
  },
  props: {
    groupIds: Array,
  },
  mixins: [factoryLineMix, areaColorType, LoadMapMix],
  data() {
    return {
      // 地图
      // map: null,
      //   地区entityid
      entityIds: new Set(),
      // 开发群entityId
      kfEntityIds: new Set(),
      // 发开区list
      kfs: [],
      //   当前选定的地区
      currentArea: null,
      //   选中区域的颜色
      currentFill: Cesium.Color.fromCssColorString("#10bfb2"),
      currentOuter: Cesium.Color.LIGHTBLUE,
      //   区域初始颜色
      areaFill: Cesium.Color.fromCssColorString("#024476"),
      areaOuter: Cesium.Color.fromCssColorString("#000"),
      // areaOuter: Cesium.Color.PALEGREEN,

      // 选择区域原色
      originFill: null,
      originOuter: null,
      // areaOuter: Cesium.Color.PALEGREEN,
      num: 0,
      dealRegionList: [],

      // 保留展示名称的开发区
      showNameKF: null,
    };
  },
  methods: {
    // 添加事件处理
    addMapEvent() {
      let handler = new Cesium.ScreenSpaceEventHandler(this.map.scene.canvas);
      handler.setInputAction((event) => {
        let obj = this.map.scene.pick(event.position);
        if (Cesium.defined(obj) && obj.id !== "") {
          let temp = obj.id;
          if (temp instanceof Cesium.Entity) {
            if (this.entityIds.has(temp.id)) {
              if (this.currentArea && this.currentArea.id != temp.id) {
                this.currentArea.polygon.material =
                  this.originFill || this.areaFill;
                this.currentArea.polygon.outlineColor =
                  this.originOuter || this.areaOuter;
              }
              if (!this.currentArea || this.currentArea.id != temp.id) {
                this.originFill = temp.polygon.material;
                this.originOuter = temp.polygon.outlineColor;
                temp.polygon.material = this.currentFill;
                temp.polygon.outlineColor = this.currentOuter;
                // this.$emit(
                //   "area-click",
                //   temp.properties["乡"]
                // );
                this.currentArea = temp;
                this.showAreaInfo(temp);
              }
            }
            if (this.kfEntityIds.has(temp.id)) {
              if (this.currentArea && this.currentArea.id != temp.id) {
                this.currentArea.polygon.material =
                  this.originFill || this.areaFill;
                this.currentArea.polygon.outlineColor =
                  this.originOuter || this.areaOuter;
              }
              if (!this.currentArea || this.currentArea.id != temp.id) {
                this.originFill = temp.polygon.material;
                this.originOuter = temp.polygon.outlineColor;
                temp.polygon.material = this.currentFill;
                temp.polygon.outlineColor = this.currentOuter;
                // this.$emit(
                //   "area-click",
                //   temp.properties["乡"]
                // );
                this.currentArea = temp;
                this.showKFInfo(temp);
              }
            }
          }
          return;
        }
        this.$refs.infowindow.close();
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler.setInputAction((event) => {
        let obj = this.map.scene.pick(event.endPosition);
        if (Cesium.defined(obj) && obj.id !== "") {
          let temp = obj.id;
          if (temp instanceof Cesium.Entity) {
            if (this.kfEntityIds.has(temp.id)) {
              temp.label.show = true;
              this.showNameKF = temp;
              return;
            }
          }
        }
        if (this.showNameKF) {
          this.showNameKF.label.show = false;
          this.showNameKF = null;
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    },

    // 查询开发区
    getRegion() {
      this.$post(this.$api.REGION_MAP.LIST, {
        pageNo: 1,
        pageSize: 9999,
        show: "1",
      }).then((res) => {
        this.kfs = res.list;
        this.dealRegion(res.list);
        this.dealRegionList = res.list;
        // if (this.num == 1) {
        //   this.dealRegion(res.list);
        // } else {
        //   let a;
        //   let groupList = [];
        //   for (let i of this.groupIds) {
        //     a = res.list.filter((item) => item.groupId == i);
        //     groupList.push(a[0]);
        //   }
        //   this.dealRegion(groupList);
        // }
      });
    },
    // 处理开发区数据
    dealRegion(list) {
      let tempover;
      tempover = this.map.entities.removeAll();
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let overlays = JSON.parse(item.position);
        for (let j = 0; j < overlays.length; j++) {
          let overlay = overlays[j];
          if (overlay.type == "polygon") {
            let coordinates = [];
            JSON.parse(overlay.options.path).forEach((temp) => {
              coordinates.push(+temp.lng);
              coordinates.push(+temp.lat);
            });
            let points = Cesium.Cartesian3.fromDegreesArray(coordinates);
            let pyCenter = Cesium.BoundingSphere.fromPoints(points).center;
            let option = {
              id: "kf" + item.id,
              position: pyCenter,
              label: {
                text: item.name,
                font: "18px",
                eyeOffset: new Cesium.Cartesian3(0, 0, -4000),
                fillColor: Cesium.Color.WHITE,
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 2,
                style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                showBackground: true,
                backgroundColor: Cesium.Color.WHITE.withAlpha(0),
                show: false,
              },
              polygon: {
                hierarchy: points,
                extrudedHeight: 2010,
                material: Cesium.Color.fromCssColorString(
                  overlay.options.fillColor
                ).withAlpha(+overlay.options.fillOpacity),
                outline: true,
                outlineColor: Cesium.Color.fromCssColorString(
                  overlay.options.strokeColor
                ).withAlpha(+overlay.options.strokeOpacity),
                outlineWidth: +overlay.options.strokeWeight,
              },
            };
            let entity = this.map.entities.add(option);
            this.kfEntityIds.add("kf" + item.id);
          }
        }
      }
    },
    // 展示区域信息
    showAreaInfo(entity) {
      let currentName = entity?.properties?.name?._value || "";
      if (currentName) {
        let area = areaList.find((item) => item.name == currentName);
        if (!area) {
          return;
        }

        let showData = {
          guimo: "",
          tudi: "",
          bili: "",
        };
        //   this.$post(this.$api.STATICS_MANAGE.GET2, {
        //     resourceTypeId: area.id,
        //   }).then((res) => {
        //     res.forEach((item) => {
        //       if (item.dimensionName == "养殖规模") {
        //         showData.guimo = item.content;
        //       }
        //       if (item.dimensionName == "消纳土地规模") {
        //         showData.tudi = item.content;
        //       }
        //       if (item.dimensionName == "资源化利用比例") {
        //         showData.bili = item.content;
        //       }
        //     });
        //     this.$refs.infowindow.setContent(
        //       `
        //     <div style=" color: #fff;font-size: 18px;">
        //   <span>养殖规模:</span>
        //   <span>${showData.guimo}</span>
        // </div>
        // <div style=" color: #fff;font-size: 18px;">
        //   <span>消纳土地规模:</span>
        //   <span>${showData.tudi}</span>
        // </div>
        // <div style=" color: #fff;font-size: 18px;">
        //   <span>资源化利用比例:</span>
        //   <span>${showData.bili}</span>
        // </div>
        //     `
        //     );
        //     this.$refs.infowindow.show(entity.position);
        //   });
        this.$post(this.$api.FAMRMING.REGION, {
          regionName: currentName,
        }).then((res) => {
          this.$emit('area-stat', res)
          this.$refs.infowindow.setContent(
            `
          <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>养殖企业：</span>
        <span>${res.userFarmers + "个"}</span>
      </div>
      <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>常年存栏量：</span>
        <span>${res.annualStock + "(头/羽/只)"}</span>
      </div>
      <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>现畜禽存栏总数：</span>
        <span>${res.tsolap + "(头/羽/只)"}</span>
      </div>
         <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>存栏生猪当量数：</span>
        <span>${res.nobeis + "(头)"}</span>
      </div>
         <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>粪污综合利用量：</span>
        <span>${res.combinedUseOfFecalWaste + "(吨)"}</span>
      </div>
         <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>有机肥综合生产量：</span>
        <span>${res.comprehensiveOrganicFertilizerProduction + "(吨)"}</span>
      </div>
         <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>粪污产生量：</span>
        <span>${res.fecalWasteProduction + "(吨)"}</span>
      </div>
         <div style=" color: #fff;font-size: 14px;padding:4px 0;">
        <span>现商品畜禽存栏：</span>
        <span>${res.soclap + "(头/羽/只)"}</span>
      </div>
          `
          );
          this.$refs.infowindow.show(entity.position);
        });
      }
    },
    // 展示开发区信息
    showKFInfo(entity) {
      let id = entity.id.substring(2);
      let kf = this.kfs.find((item) => item.id == id);
      if (kf) {
        this.$refs.infowindow.setContent(kf.content);
        this.$refs.infowindow.show(entity.position);
      }
    },
  },
  mounted() {
    this.initMap();
  },
  watch: {
    groupIds: {
      immediate: true,
      deep: true,
      handler(val) {
        // this.getRegion();
        if (val.length == 0) {
          this.num = 1;
        } else {
          this.num++;
        }
        if (this.num == 1) {
          if (this.dealRegionList.length > 0) {
            this.dealRegion(this.dealRegionList);
          }
        } else {
          let a;
          let groupList = [];
          for (let i of val) {
            a = this.dealRegionList.filter((item) => item.groupId == i);
            groupList.push(a[0]);
          }
          this.dealRegion(groupList);
        }
      },
    },
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>